import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import { startTransition, StrictMode, useEffect } from "react"
import { hydrateRoot } from "react-dom/client"

Sentry.init({
  dsn: "https://410b10904c0c4ce2a1a8bdb1010698b3:13dcddfd6b1a4ff4a08529e1479ba3df@o471.ingest.sentry.io/4504907767414784",
  release: "SENTRY-RELEASE-PLACEHOLDER",
  includeLocalVariables: true,
  tracesSampleRate: 0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches,
      ),
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
